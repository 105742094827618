<template>
  <div>
    <div class="table-operator" style="margin-bottom: 15px">
      <a-button type="primary" icon="plus" @click="modalAddEditLoad()">添加</a-button>
    </div>

    <s-table
      ref="pageTable"
      :rowKey="(record) => record.id"
      :columns="columns"
      :data="dataset"
      :pagination="pagination"
      :pageSize="10"
      :scroll="{}"
    >
      <a slot="name" slot-scope="text">{{ text }}</a>
      <span slot="operation" slot-scope="text, record">
        <a @click="modalRouteLoad(record.id)">管理路线</a> | 
        <a @click="modalAddEditLoad(record)">编辑</a> |
        <a-popconfirm
          title="您确认要删除此条记录吗？"
          ok-text="确认"
          cancel-text="取消"
          @confirm="handleRemove(record)"
        >
          <a>删除</a>
        </a-popconfirm>
      </span>
    </s-table>

    <a-modal
      key="addEdit"
      title="新增/编辑"
      :width="550"
      :footer="null"
      :centered="true"
      :keyboard="false"
      :maskClosable="false"
      :visible="modalAddEditVisible"
      @cancel="modalAddEditCancel"
    >
      <a-form :form="modalAddEditForm" @submit="modalAddEditSubmit">
        <a-form-item
          label="名称"
          :labelCol="{ lg: { span: 4 }, sm: { span: 6 } }"
          :wrapperCol="{ lg: { span: 18 }, sm: { span: 12 } }"
        >
          <a-input name="name" v-decorator="['name', modalAddEditFormRules.name]" />
        </a-form-item>

        <a-form-item class="form-submit">
          <a-input name="id" type="hidden" style="display: none" v-decorator="['id', modalAddEditFormRules.id]" />
          <a-button style="margin-right: 15px" @click="modalAddEditCancel">取消</a-button>
          <a-button type="primary" htmlType="submit">提交</a-button>
        </a-form-item>
      </a-form>
    </a-modal>

    <a-modal
      key="routeManage"
      title="管理路线"
      :width="1100"
      :footer="null"
      :centered="true"
      :keyboard="false"
      :maskClosable="false"
      :visible="modalRouteVisible"
      @cancel="modalRouteCancel"
    >
        <div class="table-operator-admin">
          <div>
          <a-button type="primary" icon="plus" @click="modalRouteAddEditLoad()" style="margin-right:10px">添加</a-button>
          <a-upload name="file" :beforeUpload="beforeUpload" :showUploadList="false" accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" style="display:flex">
            <a-button type="primary" icon="upload">上传一口价</a-button>
          </a-upload>
          </div>
          <a-button type="primary" icon="download" @click="downloadExcel()" style="margin-left:10px">下载一口价</a-button>
        </div>

        <div v-show="modalRouteType == 1">
          <s-table
            ref="modalTable" size="middle"
            :rowKey="(record) => record.id"
            :columns="routeColumns"
            :data="routeDataset"
            :pagination="routePagination"
            :pageSize="10"
          >
            <a slot="name" slot-scope="text">{{ text }}</a>
            <span slot="operation" slot-scope="text, record">
              <a-popconfirm
                title="您确认要删除此条记录吗？"
                ok-text="确认"
                cancel-text="取消"
                @confirm="handleRouteRemove(record)"
              >
                <a>删除</a>
              </a-popconfirm>
            </span>
          </s-table>
        </div>
        <div v-show="modalRouteType == 2" class="formAdd">
          <a-form :form="modalRouteAddEditForm" @submit="modalRouteAddEditSubmit">
            <a-row>
              <a-col :xl="12" :lg="24" :md="24" :sm="24">
                <a-form-item
                  label="起始地"
                  :labelCol="{ lg: { span: 10 }, sm: { span: 10 } }"
                  :wrapperCol="{ lg: { span: 16 }, sm: { span: 12 } }"
                >
                  <a-cascader
                    name="fromGeoCode"
                    placeholder="请选择起始地"
                    v-decorator="['fromGeoCode', modalRouteAddEditFormRules.fromGeoCode]"
                    :options="geoCascaderList"
                  />
                </a-form-item>
              </a-col>

              <a-col :xl="12" :lg="24" :md="24" :sm="24">
                <a-form-item
                  label="目的地"
                  :labelCol="{ lg: { span: 10 }, sm: { span: 10 } }"
                  :wrapperCol="{ lg: { span: 16 }, sm: { span: 12 } }"
                >
                  <a-cascader
                    name="toGeoCode"
                    placeholder="请选择目的地"
                    v-decorator="['toGeoCode', modalRouteAddEditFormRules.toGeoCode]"
                    :options="geoCascaderList"
                  />
                </a-form-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :xl="12" :lg="24" :md="24" :sm="24">
                <a-form-item
                  label="首重10KG"
                  :labelCol="{ lg: { span: 7 }, sm: { span: 6 } }"
                  :wrapperCol="{ lg: { span: 12 }, sm: { span: 12 } }"
                >
                  <a-input-number
                    name="range1FirstWeightPrice"
                    :min="0"
                    :step="0.01"
                    v-decorator="['range1FirstWeightPrice', modalRouteAddEditFormRules.range1FirstWeightPrice]"
                  />
                  <a-button disabled class="percentage"> 元</a-button>
                </a-form-item>
              </a-col>

              <a-col :xl="12" :lg="24" :md="24" :sm="24">
                <a-form-item
                  label="超10KG续重"
                  :labelCol="{ lg: { span: 7 }, sm: { span: 6 } }"
                  :wrapperCol="{ lg: { span: 12 }, sm: { span: 12 } }"
                >
                  <a-input-number
                    name="range1ContinuedWeightPrice"
                    :min="0"
                    :step="0.01"
                    v-decorator="['range1ContinuedWeightPrice', modalRouteAddEditFormRules.range1ContinuedWeightPrice]"
                  />
                  <a-button disabled class="percentage"> 元</a-button>
                </a-form-item>
              </a-col>

              <a-col :xl="12" :lg="24" :md="24" :sm="24">
                <a-form-item
                  label="超500KG续重"
                  :labelCol="{ lg: { span: 7 }, sm: { span: 6 } }"
                  :wrapperCol="{ lg: { span: 12 }, sm: { span: 12 } }"
                >
                  <a-input-number
                    name="range4ContinuedWeightPrice"
                    :min="0"
                    :step="0.01"
                    v-decorator="['range4ContinuedWeightPrice', modalRouteAddEditFormRules.range4ContinuedWeightPrice]"
                  />
                  <a-button disabled class="percentage"> 元</a-button>
                </a-form-item>
              </a-col>

              <a-col :xl="12" :lg="24" :md="24" :sm="24">
                <a-form-item
                  label="超1200KG续重"
                  :labelCol="{ lg: { span: 7 }, sm: { span: 6 } }"
                  :wrapperCol="{ lg: { span: 12 }, sm: { span: 12 } }"
                >
                  <a-input-number
                    name="range5ContinuedWeightPrice"
                    :min="0"
                    :step="0.01"
                    v-decorator="['range5ContinuedWeightPrice', modalRouteAddEditFormRules.range5ContinuedWeightPrice]"
                  />
                  <a-button disabled class="percentage"> 元</a-button>
                </a-form-item>
              </a-col>
            </a-row>

            <a-form-item class="form-submit">
              <a-button style="margin-right: 10px" @click="modalRouteAddEditCancel">取消</a-button>
              <a-button type="primary" htmlType="submit">提交</a-button>
            </a-form-item>
          </a-form>
        </div>
        <div v-show="modalRouteType == 3">
          <a-table ref="modalUploadTable" size="middle"
            :rowKey="(record) => record.idx"
            :columns="routeColumns"
            :data-source="modalUploadDataset"
            :pagination="routePagination"
            :pageSize="10"
          >
            <a slot="name" slot-scope="text">{{ text }}</a>
          </a-table>
          <div style="text-align:right;width:100%;margin-top:10px">
            <a-button @click="modalUploadCancel()" style="margin-right:10px">取消</a-button>
            <a-button type="primary" htmlType="submit" :loading="buttonLoading.importSave" @click="modalUploadSubmit()">保存</a-button>
          </div>
        </div>

    </a-modal>

  </div>
</template>
<script>
import { STable } from '@/components'
import { fixedPriceList, fixedPriceInsert, fixedPriceUpdate, fixedPriceRemove, fixedPriceRouteList, fixedPriceRouteInsert, fixedPriceRouteRemove, geoCascaderList} from '@/api/quotation'
import XLSX from 'xlsx'

const columns = [
  {
    key: 'name',
    dataIndex: 'name',
    title: '名称',
    align: 'center',
  },
  {
    key: 'updateDate',
    dataIndex: 'updateDate',
    title: '更新时间',
    align: 'center',
  },
  {
    key: 'updateName',
    dataIndex: 'updateName',
    title: '操作人',
    align: 'center',
  },
  {
    key: 'operation',
    dataIndex: 'operation',
    title: '操作',
    align: 'center',
    scopedSlots: {
      customRender: 'operation',
    },
  },
]
const routeColumns = [
  {
    key: 'fromProvinceName',
    dataIndex: 'fromProvinceName',
    title: '起始省份',
    align: 'center',
  },
  {
    key: 'fromCityName',
    dataIndex: 'fromCityName',
    title: '起始城市',
    align: 'center',
  },
  {
    key: 'toProvinceName',
    dataIndex: 'toProvinceName',
    title: '目的省份',
    align: 'center',
  },
  {
    key: 'toCityName',
    dataIndex: 'toCityName',
    title: '目的城市',
    align: 'center',
  },
  {
    key: 'range1FirstWeightPrice',
    dataIndex: 'range1FirstWeightPrice',
    title: '首重10KG',
    align: 'center',
  },
  {
    key: 'range1ContinuedWeightPrice',
    dataIndex: 'range1ContinuedWeightPrice',
    title: '超10KG续重（元/KG）',
    align: 'center',
  },
  {
    key: 'range4ContinuedWeightPrice',
    dataIndex: 'range4ContinuedWeightPrice',
    title: '超500KG续重（元/KG）',
    align: 'center',
  },
  {
    key: 'range5ContinuedWeightPrice',
    dataIndex: 'range5ContinuedWeightPrice',
    title: '超1200KG续重（元/KG）',
    align: 'center',
  },
  {
    key: 'operation',
    dataIndex: 'operation',
    title: '操作',
    align: 'center',
    scopedSlots: {
      customRender: 'operation',
    },
  },
]
const modalAddEditFormRules = {
  id: { initialValue: null },
  name: { rules: [{ required: true, message: '请输入名称' }] },
}

const modalRouteAddEditFormRules = {
  fromGeoCode: { rules: [{ required: true, message: '请选择起始地' }] },
  toGeoCode: { rules: [{ required: true, message: '请选择目的地' }] },
  range1FirstWeightPrice: { rules: [{ required: true, message: '请输入首重10KG价格' }] },
  range1ContinuedWeightPrice: { rules: [{ required: true, message: '请输入超10KG续重价格' }] },
  range4ContinuedWeightPrice: { rules: [{ required: true, message: '请输入超500KG续重价格' }] },
  range5ContinuedWeightPrice: { rules: [{ required: true, message: '请输入超1200KG续重价格' }] },
}

const excelConfig = {
  "fromProvinceName": "起始省份",
  "fromCityName": "起始城市",
  "toProvinceName": "目的省份",
  "toCityName": "目的城市",
  "range1FirstWeightPrice": "首重10KG",
  "range1ContinuedWeightPrice": "超10KG续重（元/KG）",
  "range4ContinuedWeightPrice": "超500KG续重（元/KG）",
  "range5ContinuedWeightPrice": "超1200KG续重（元/KG）",
};

export default {
  name: 'TableList',
  components: {
    STable,
  },
  data() {
    return {
      columns: columns,
      dataset: (parameter) => {
        return fixedPriceList(Object.assign(parameter, this.queryParam))
          .then((res) => {
            if (0 == res.code) {
              return res.result
            }
          })
          .catch((e) => {
            console.info(e)
            this.$message.error('数据加载失败，请稍后重试')
          })
      },
      pagination: { pageSizeOptions: ['10', '20', '50'] },
      queryParam: {},
      routeColumns: routeColumns,
      routeDataset: (parameter) => {
        if (this.routeQueryParam.fixedPriceId > 0) {
          return fixedPriceRouteList(Object.assign(parameter, this.routeQueryParam))
            .then((res) => {
              if (0 == res.code) {
                return res.result
              }
            })
            .catch((e) => {
              console.info(e)
              this.$message.error('数据加载失败，请稍后重试')
            })
        } else {
          return this.handleEmptyList()
        }
      },
      routePagination: { pageSizeOptions: ['10'] },
      routeQueryParam: { fixedPriceId: 0 },
      modalAddEditVisible: false,
      modalAddEditForm: this.$form.createForm(this),
      modalRouteAddEditForm: this.$form.createForm(this),
      modalAddEditFormRules: modalAddEditFormRules,
      modalRouteAddEditFormRules: modalRouteAddEditFormRules,
      modalRouteType: 1,  // 1列表, 2表单, 3上传ßßßßß
      modalRouteVisible: false,
      modalUploadfixedPriceId: 0,
      modalUploadVisible: false,
      modalUploadDataset: [],
      geoCascaderList: [],
      downloadExcelState: 0,
      downloadExcelDataset: [],
      buttonLoading: {
        importSave: false,
      },
    }
  },
  mounted() {
    const parameter = {}
    geoCascaderList(parameter)
      .then((res) => {
        if (0 == res.code) {
          this.geoCascaderList = res.result
        }
      })
      .catch((e) => {
        console.info(e)
      })
  },
  methods: {
    modalAddEditLoad(record = null) {
      this.modalAddEditForm.resetFields()
      this.modalAddEditVisible = true
      if (record) {
        this.$nextTick(() => {
          this.modalAddEditForm.setFieldsValue({
            id: record.id,
            name: record.name,
          })
        })
      }
    },
    modalAddEditCancel() {
      this.modalAddEditVisible = false
    },
    modalAddEditSubmit(e) {
      e.preventDefault()
      let that = this
      this.modalAddEditForm.validateFields((err, values) => {
        if (!err) {
          if (values.id) {
            that.handleUpdate(values)
          } else {
            that.handleInsert(values)
          }
        }
      })
    },
    handleInsert(parameter) {
      fixedPriceInsert(parameter)
        .then((res) => {
          if (0 == res.code) {
            this.$message.success('保存成功')
            this.modalAddEditVisible = false
            this.$refs.pageTable.refresh(true)
          } else {
            this.$message.error('保存失败')
          }
        })
        .catch((e) => {
          console.info(e)
          this.$message.error('保存失败，请稍后重试')
        })
    },
    handleUpdate(parameter) {
      fixedPriceUpdate(parameter)
        .then((res) => {
          if (0 == res.code) {
            this.$message.success('保存成功')
            this.modalAddEditVisible = false
            this.$refs.pageTable.refresh(true)
          } else {
            this.$message.error('保存失败')
          }
        })
        .catch((e) => {
          console.info(e)
          this.$message.error('保存失败，请稍后重试')
        })
    },
    handleRemove(record) {
      fixedPriceRemove(record)
        .then((res) => {
          if (0 == res.code) {
            this.$message.success('删除成功')
            this.$refs.pageTable.refresh(true)
          } else {
            this.$message.error('删除失败')
          }
        })
        .catch((e) => {
          console.info(e)
          this.$message.error('删除失败，请稍后重试')
        })
    },
    modalRouteLoad(fixedPriceId) {
      this.modalRouteType = 1
      this.modalRouteVisible = true
      this.routeQueryParam.fixedPriceId = fixedPriceId
      this.$nextTick(() => {
        this.$refs.modalTable.refresh(true)
      })
    },
    modalRouteCancel() {
      this.modalRouteVisible = false
      this.routeQueryParam.fixedPriceId = 0
      this.$nextTick(() => {
        this.$refs.modalTable.refresh(true)
      })
    },
    modalRouteAddEditLoad(){
      this.modalRouteType = 2
      this.modalRouteAddEditForm.resetFields()
    },
    modalRouteAddEditSubmit(e){
      e.preventDefault()
      let that = this
      let fixedPriceId = that.routeQueryParam.fixedPriceId
      if (fixedPriceId == 0) {
        return;
      }
      this.modalRouteAddEditForm.validateFields((err, values) => {
        if (!err) {
          let record = {
            fixedPriceId: fixedPriceId,
            fromProvinceCode: values.fromGeoCode[0],
            fromCityCode: values.fromGeoCode[1],
            toProvinceCode: values.toGeoCode[0],
            toCityCode: values.toGeoCode[1],
            range1FirstWeightPrice: values.range1FirstWeightPrice,
            range1ContinuedWeightPrice: values.range1ContinuedWeightPrice,
            range4ContinuedWeightPrice: values.range4ContinuedWeightPrice,
            range5ContinuedWeightPrice: values.range5ContinuedWeightPrice,
          }
          let parameter = {"recordList": [record]};
          fixedPriceRouteInsert(parameter)
            .then(res => {
              if (0 == res.code) {
                that.$message.success('保存成功');
                that.modalRouteType = 1
                that.$refs.modalTable.refresh(true);
              } else {
                that.$message.error('保存失败');
              }
            }).catch(e => {
              console.info(e);
              that.$message.error('保存失败，请稍后重试');
            })
        }
      })
    },
    modalRouteAddEditCancel(){
      this.modalRouteType = 1
    },
    handleRouteRemove(record) {
      fixedPriceRouteRemove(record)
        .then((res) => {
          if (0 == res.code) {
            this.$message.success('删除成功')
            this.$refs.modalTable.refresh(true)
          } else {
            this.$message.error('删除失败')
          }
        })
        .catch((e) => {
          console.info(e)
          this.$message.error('删除失败，请稍后重试')
        })
    },
    handleEmptyList() {
      return new Promise(function (resolve, reject) {
        const result = {
          pageNo: 1,
          pageSize: 5,
          pageCount: 0,
          totalCount: 0,
          result: [],
          startIndex: 0,
          endIndex: 0,
          first: 1,
        }
        resolve(result)
      })
    },
    beforeUpload(file){
      let that = this;
      let fixedPriceId = that.routeQueryParam.fixedPriceId
      if (fixedPriceId == 0) {
        return;
      }
      let reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = (e) => {
          let workbook = XLSX.read(e.target.result, {type: 'binary'});
          let worksheet = workbook.Sheets[workbook.SheetNames[0]];
          let result = XLSX.utils.sheet_to_json(worksheet);
          console.log(["xlxs-sheet", result])
          let dateset = [];
          for (let i = 0; i < result.length; i++) {
            let row = {"idx": i};
            let importState = true
            for (let key in excelConfig) {
              let name = excelConfig[key];
              let value = result[i][name];
              if (key == "fromCityName") {
                let geoInfo = this.geoSearchByName(value)
                if (geoInfo == null) {
                  importState = false;
                  console.log(["xlxs-break", key, value])
                  break;
                }
                row["fromProvinceCode"] = geoInfo.provinceCode;
                row["fromProvinceName"] = geoInfo.provinceName;
                row["fromCityCode"] = geoInfo.cityCode;
                row["fromCityName"] = geoInfo.cityName;
              }
              else if (key == "toCityName") {
                let geoInfo = this.geoSearchByName(value);
                if (geoInfo == null) {
                  importState = false;
                  console.log(["xlxs-break", key, value])
                  break;
                }
                row["toProvinceCode"] = geoInfo.provinceCode;
                row["toProvinceName"] = geoInfo.provinceName;
                row["toCityCode"] = geoInfo.cityCode;
                row["toCityName"] = geoInfo.cityName;
              }
              else if (key == "fromProvinceName" || key == "toProvinceName") {
                continue;
              }
              else {
                row[key] = value;
              }
              if (typeof(value) == 'undefined' || (typeof(value) == 'string' && value.trim() == "")) {
                importState = false;
                console.log(["xlxs-break", key, value])
                break;
              }
            }
            if (importState && Object.keys(row).length > 1) {
              row["fixedPriceId"] = fixedPriceId
              dateset.push(row);
            }
          }
          that.modalUploadDataset = dateset;
          that.modalUploadLoad();
      };
      return false;
    },
    geoSearchByName(name){
      for (let i = 0; i < this.geoCascaderList.length; i++) {
        let provinceInfo = this.geoCascaderList[i];
        let cityList = provinceInfo.children;
        for (let j = 0; j < cityList.length; j++) {
          let cityInfo = cityList[j];
          if (cityInfo.label.indexOf(name) !== -1) {
            return {
              "provinceCode": provinceInfo.value,
              "provinceName": provinceInfo.label,
              "cityCode": cityInfo.value,
              "cityName": cityInfo.label,
            }
          }
        }
      }
      return null;
    },
    modalUploadLoad(){
      this.modalRouteType = 3
      this.modalUploadVisible = true;
      console.log(this.modalUploadDataset)
    },
    modalUploadCancel(){
      this.modalRouteType = 1
      this.modalUploadVisible = false;
    },
    modalUploadSubmit(e){
      let parameter = {"recordList": this.modalUploadDataset};
      this.buttonLoading.importSave = true;
      fixedPriceRouteInsert(parameter)
        .then(res => {
          if (0 == res.code) {
            this.$message.success('保存成功');
            this.modalRouteType = 1
            this.modalUploadVisible = false;
            this.$refs.modalTable.refresh(true);
          } else {
            this.$message.error('保存失败');
          }
          this.buttonLoading.importSave = false;
        }).catch(e => {
          console.info(e);
          this.buttonLoading.importSave = false;
          this.$message.error('保存失败，请稍后重试');
        })
    },
    downloadExcel(){
      if (this.downloadExcelState > 0 && this.routeQueryParam.fixedPriceId > 0) {
        return;
      }
      this.downloadExcelState = 1;
      this.downloadExcelDataset = [];

      let downloadExcelTitle = [];
      for (let key in excelConfig) {
        downloadExcelTitle.push(excelConfig[key]);
      }
      this.downloadExcelDataset.push(downloadExcelTitle);

      let parameter = {pageNo: 1, pageSize: 100};
      this.downloadDataset(parameter);
    },
    downloadDataset(parameter) {
      let that = this;
      fixedPriceRouteList(Object.assign(parameter, this.routeQueryParam))
        .then(res => {
          if (0 == res.code) {
            console.log(res.result);
            const result = res.result.result;
            for (let i = 0; i < result.length; i++) {
              const dataset = [
                result[i].fromProvinceName,
                result[i].fromCityName,
                result[i].toProvinceName,
                result[i].toCityName,
                result[i].range1FirstWeightPrice,
                result[i].range1ContinuedWeightPrice,
                result[i].range4ContinuedWeightPrice,
                result[i].range5ContinuedWeightPrice
              ];
              that.downloadExcelDataset.push(dataset);
            }
            const pageNo = res.result.pageNo;
            const pageSize = res.result.pageSize;
            const totalCount = res.result.totalCount;
            const totalPage = Math.ceil(totalCount / pageSize);
            if (pageNo >= totalPage) {
              console.log(that.downloadExcelDataset);
              let sheet = XLSX.utils.aoa_to_sheet(that.downloadExcelDataset);
              let book = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(book, sheet, '一口价表');
              XLSX.writeFile(book, '一口价表.xls');
              that.downloadExcelState = 0
              return;
            }
            parameter.pageNo = pageNo + 1;
            that.downloadDataset(parameter);
          }
        }).catch(e => {
          console.info(e);
          that.downloadExcelState = 0
          that.$message.error('下载失败，请稍后重试');
        });
    }
  },
}
</script>
<style lang="less" scoped>
/deep/ .ant-table-tbody{
  background-color: #fff;
}
/deep/  .form-submit {
    margin-bottom: 0;
  .ant-form-item-control-wrapper{
    width: 100% !important;
    .ant-form-item-children{
      float: right;
    }
  }
}
</style>
<style lang="less">
 .ant-popover-message{
  width: auto !important;
  height: auto !important;
}
 .ant-form-item-label{
  text-align: left !important;
}
/deep/ .ant-table-pagination {
 > li{
  background-color: #fff !important;
  min-width: 35px !important;
  height: 35px !important;
  line-height: 35px !important;
  border-radius: 4px;
  box-sizing: border-box;
  >div{
    margin: 0 0;
    >div{
      border: none;
    }
   }
  }
  .ant-pagination-options{
   border:1px solid #d9d9d9;
  .ant-select-selection--single {
    height: 32px;
    .ant-select-selection__rendered{
      line-height: 35px;
    }
  }
  }
  .ant-pagination-item-active{
    border-color: #1890ff !important;
  }
}

</style>
<style lang="less" scoped>
  .ant-form{
   /deep/ .ant-form-item-control .ant-form-item-children{
      .ant-input-number{
        border-radius: 5px 0 0 5px;
      }
      .percentage{
        border-radius:0 5px 5px 0;
        border-left: 0;
      }
      .ant-cascader-picker {
        width: 160px;
      }
    }
  }
  /deep/ .ant-form-item-label{
    width: 25%;
    text-align: left !important;
}
.ant-form-item{
  display: flex;
}
.formAdd{
  /deep/ .ant-input-number{
    width: 60%;
    line-height: 2.2;
  }
  /deep/ .ant-cascader-picker{
    width: 58% !important;
  }
}
.table-operator-admin{
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  >div{
    display: flex;
  }
  /deep/ .ant-upload-list{
    display: flex;
    flex-wrap: wrap;
  }
  /deep/  .ant-upload-list-item{
    margin-top: 5px !important;
    .ant-upload-list-item-info .ant-upload-list-item-name{
      padding-right:10px;
    }
  }
  /deep/ .anticon-close{
    font-size: 15px !important;
  }
}
/deep/  .form-submit {
  .ant-form-item-children {
    justify-content: flex-end;
  }
}
</style>